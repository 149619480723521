import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'landingPagePart',
    component: () =>
      import(
        /* webpackChunkName: "landingPage-Chunk" */ '../views/view__landingPage.vue'
      )
  },
  {
    path: '/franchise/:subPath(.*)',
    name: 'franchiseOldRoute',
    beforeEnter: (to, from, next) => {
      next({
        name: 'detailPage',
        params: {
          slug: to.params.subPath
        }
      })
    }
  },
  {
    path: '/cari',
    name: 'searchPage',
    component: () =>
      import(
        /* webpackChunkName: "searchPage-Chunk" */ '../views/view_searchPage.vue'
      )
  },
  {
    path: '/bisnis/:slug',
    name: 'detailPage',
    component: () =>
      import(
        /* webpackChunkName: "detailPage-Chunk" */ '../views/view_detailPage.vue'
      )
  },
  {
    path: '/peta-situs',
    name: 'petaSitusPage',
    component: () =>
      import(
        /* webpackChunkName: "peta-situs-Chunk" */ '../views/view__sitemap.vue'
      )
  },
  {
    path: '/campaign/form/:id',
    name: 'campaignPage',
    component: () =>
      import(
        /* webpackChunkName: "campaign-Chunk" */ '../views/view__campainPage.vue'
      )
  },
  {
    path: '/category-populer',
    name: 'categoryPage',
    component: () =>
      import(
        /* webpackChunkName: "categoryPage-Chunk" */ '../views/view__categoryPage.vue'
      )
  },
  {
    path: '/blog',
    name: 'blogPage',
    component: () =>
      import(
        /* webpackChunkName: "blogPage-Chunk" */ '../views/view_blogPage.vue'
      )
  },
  {
    path: '/blogDetail/:id',
    name: 'blogDetailPage',
    component: () =>
      import(
        /* webpackChunkName: "blogPage-Chunk" */ '../component/blogComponent/blogDetail.vue'
      )
  },
  {
    path: '/syarat-ketentuan',
    name: 'SyaratKetentuanPage',
    component: () =>
      import(
        /* webpackChunkName: "footerGroup-Chunk" */ '../views/view_S&Kpage.vue'
      )
  },
  {
    path: '/kebijakan-privasi',
    name: 'PrivacyRulesPage',
    component: () =>
      import(
        /* webpackChunkName: "footerGroup-Chunk" */ '../views/view_privacyPage.vue'
      )
  },
  {
    path: '/tentang-rintisan',
    name: 'tentangRintisanPage',
    component: () =>
      import(
        /* webpackChunkName: "footerGroup-Chunk" */ '../views/view_aboutRintisanPage.vue'
      )
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log(to)
//   console.log(from)
// })
export default router
