import { createStore } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import {
  interCeptor,
  landingPage,
  globalState,
  blogPage,
  detailPage,
  campainPage
} from './modules'
import { getAPI } from './API'
// import SecureLS from 'secure-ls'
// const ls = new SecureLS({
//   encodingType: 'rabbit',
//   isCompression: true,
//   encryptionSecret: 'secret_rintisan_no_one_know'
// })
export default createStore({
  state: {},
  mutations: {},
  actions: {
    siteMapGet() {
      try {
        return getAPI('index/sitemap/data').then(({ data }) => {
          return data
        })
      } catch (error) {
        return error
      }
    }
  },
  modules: {
    interCeptor,
    landingPage,
    globalState,
    blogPage,
    detailPage,
    campainPage
  }
})
